import React, { ReactElement } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import ProductCard from '../components/common/productCard';

const ProductPackages = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Our Seamless Product Packages"
                desc="StackIAM is a trusted identity management cloud service that provides secured and seamless interactions between individuals and organizations. With the StackIAM platform, users can connect to all their applications and manage activities from their devices"
                imageUrl="/images/product-packages-hero@3x.png"
            />
            <HeadingSection
                heading="Products"
                subheading="Workforce Management"
            />
            <Row noGutters>
                <ProductCard
                    title="Protect and enable employees"
                    description="Verify and independently validates data on
                                platform Allows easy application and
                                registration to Business services , embassy,
                                sport etc."
                    imgUrl="images/products-user-shield@2x.png"
                />
                <ProductCard
                    title="Contractors"
                    description="Verify and independently validates data on
                    platform Allows easy application and
                    registration to Business services , embassy,
                    sport etc."
                    imgUrl="images/products-worker@2x.png"
                />
                <ProductCard
                    title="Partners"
                    description="Verify and independently validates data on
                    platform Allows easy application and
                    registration to Business services , embassy,
                    sport etc."
                    imgUrl="images/products-meeting-room@2x.png"
                />
            </Row>
            <Row noGutters>
                <div
                    style={{
                        fontSize: `1.5rem`,
                        color: `#3c4043`,
                        fontWeight: `bold`,
                        margin: `1rem`,
                    }}
                >
                    Customer Management
                </div>
            </Row>
            <Row noGutters>
                <ProductCard
                    title="Build secure"
                    description="Verify and independently validates data on
                                platform Allows easy application and
                                registration to Business services , embassy,
                                sport etc."
                    imgUrl="images/products-secure@2x.png"
                />
                <ProductCard
                    title="Seamless experiences"
                    description="Verify and independently validates data on
                    platform Allows easy application and
                    registration to Business services , embassy,
                    sport etc."
                    imgUrl="images/products-confirm@2x.png"
                />
            </Row>
            <Row noGutters>
                <div
                    style={{
                        fontSize: `1.5rem`,
                        color: `#3c4043`,
                        fontWeight: `bold`,
                        margin: `1rem`,
                    }}
                >
                    Identity Verification System
                </div>
            </Row>
            <Row noGutters>
                <ProductCard
                    title="Verified users information"
                    description="Verify and independently validates data on
                                platform Allows easy application and
                                registration to Business services , embassy,
                                sport etc."
                    imgUrl="images/products-verified@2x.png"
                />
            </Row>
            <Row noGutters>
                <div
                    style={{
                        fontSize: `1.5rem`,
                        color: `#3c4043`,
                        fontWeight: `bold`,
                        margin: `1rem`,
                    }}
                >
                    Clocker For Employees
                </div>
            </Row>
            <Row noGutters>
                <ProductCard
                    title="Time tracking for individuals or teams"
                    description="Verify and independently validates data on
                    platform Allows easy application and
                    registration to Business services , embassy,
                    sport etc."
                    imgUrl="images/products-time@2x.png"
                />
            </Row>
            <Row noGutters>
                <div
                    style={{
                        fontSize: `1.5rem`,
                        color: `#3c4043`,
                        fontWeight: `bold`,
                        margin: `1rem`,
                    }}
                >
                    Multifactor authentication
                </div>
            </Row>
            <Row noGutters>
                <ProductCard
                    title="Secured two factor authentication protocol"
                    description="Verify and independently validates data on
                                platform Allows easy application and
                                registration"
                    imgUrl="images/products-authentication@2x.png"
                />
            </Row>
        </Container>
    </Layout>
);

export default ProductPackages;
