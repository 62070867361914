import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import { productCardData } from '../../utils/entities';
import { Image } from '../../utils/image';

const StyledButton = styled(Button)`
    border-radius: 100rem;
    color: #29558c;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-right: 2rem;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to top, #9ba4d9, #94dfe7);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #ffffff, #ffffff),
        linear-gradient(to top, #9ba4d9, #94dfe7);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: solid 1px transparent;
    box-shadow: 2px 1000px 1px #fff inset;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &:hover {
        color: black;
    }
`;

const ProductCard = ({
    imgUrl,
    title,
    description,
}: productCardData): ReactElement => (
    <Col md="12" lg="4">
        <Card style={{ margin: `1rem` }}>
            <Card.Body
                style={{
                    backgroundColor: `#f7f7f7`,
                    height: `25rem`,
                }}
            >
                <Image
                    style={{
                        height: `4rem`,
                        width: `4rem`,
                        marginTop: `1rem`,
                        marginBottom: `1rem`,
                    }}
                    src={imgUrl}
                />
                <div
                    style={{
                        fontSize: `1.5rem`,
                        fontWeight: `bold`,
                    }}
                >
                    {title}
                </div>
                <p style={{ color: `#535b6c`, height: `7rem` }}>
                    {description}
                </p>
                <div>
                    <StyledButton>Learn more</StyledButton>
                </div>
            </Card.Body>
        </Card>
    </Col>
);

export default ProductCard;
